<template>
  <div>
    <h3 class="center">Должности</h3>
    <div v-if="loading">
      <Loader />
    </div>
    <div v-else>
      <div class="reminder smalltext">работает сортировка по столбцам</div>

      <table class="t_table">
        <tr class="noselect">
          <th style="width: 5%">№</th>
          <th ref="job_name_sort" @click="sort('job_name', report, $refs, oFlag)" style="width: 65%">
            Название
          </th>
          <th ref="job_rate_sort" @click="sort('job_rate', report, $refs, oFlag)" style="width: 30%">
            Ставка
          </th>
        </tr>
        <template v-if="rowsCount">
          <tr v-for="(row, y) in report" :key="y" class="job" @click="windowJobOpen(row.id)">
            <td>{{ y + 1 }}</td>
            <td>{{ row.job_name }}</td>
            <td>{{ row.job_rate }}</td>
          </tr>
        </template>
        <tr v-else>
          <td class="center" colspan="300">нет данных</td>
        </tr>
        <tr class="job">
          <td colspan="300" style="border: none; background-color: white; padding-top: 1rem">
            <div @click="windowJobOpen(0)">добавить должность</div>
          </td>
        </tr>
      </table>
    </div>
    <JobWindow :showFlag="showFlag" @hide="windowJobClose" :jobIn="job" />
  </div>
</template>

<style lang="scss" scoped>
tr.job:hover {
  cursor: pointer;
  text-decoration: underline;
}
</style>

<script>
import JobWindow from "@/components/JobWindow.vue";
import Loader from "@/components/Loader.vue";
import { request } from "@/components-js/requestSrv";
import { sortCol } from "@/components-js/sortCol.js";
import { ACTION_TYPES } from "@/store/const";

export default {
  components: { Loader, JobWindow },
  data() {
    return {
      loading: false,
      report: [],
      rowsCount: 0,
      showFlag: false,
      job: {},
      oFlag: 1,
    };
  },

  mounted() {
    this.tableDataRetrieve();
  },

  methods: {
    windowJobOpen(id) {
      if (id) {
        const pos = this.report.findIndex(el => el.id == id);
        this.job = this.report[pos];
      } else {
        this.job = null;
      }
      this.showFlag = true;
    },

    async windowJobClose(job) {
      this.showFlag = false;
      if (!job.id) return;
      await this.tableDataRetrieve();
      await this.$store.dispatch(ACTION_TYPES.GET_JOB_LIST);
    },

    sort(col, arr, refs, oFlag) {
      this.oFlag = sortCol(col, arr, refs, oFlag);
    },

    async tableDataRetrieve() {
      this.loading = true;

      const data = await request("/api/job/list");
      this.report = data;

      this.rowsCount = data.length;
      this.loading = false;
    },
  },
};
</script>
